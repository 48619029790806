// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require tinymce

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "../stylesheets/application.css.scss"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import Swiper from 'swiper';
import SwiperCore, { Mousewheel, Navigation, Pagination, Autoplay, Thumbs} from 'swiper';
SwiperCore.use([Mousewheel, Navigation, Pagination, Autoplay, Thumbs]);
import 'swiper/swiper-bundle.css';

window.$ = window.jQuery = require("jquery")

require("@nathanvda/cocoon")

require("./admin/event")
require("./flash-messages")
require("./search")
require("./footer")

$(document).on('turbolinks:load', function () {
  $("#contacts-anchor").click(function() {
    if ($(window).width() > 500) {
      window.scrollTo(0, document.body.scrollHeight);
      $("#contact_phone").focus();
    } else {
      window.scrollTo(0, (document.body.scrollHeight - 800));
    }
  });

  $(".navbar-toggler").click(function() {
    $(".navbar-brand, .search-filter").toggleClass("noactive");
    $("#navbarSupportedContent").toggleClass("show");
  });
  var swiper = new Swiper('.swiper-container', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    loop: true,

    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },

    autoplay: {
      delay: 4500,
      disableOnInteraction: false
    }

  });

  var swiper_tours = new Swiper('.swiper-tours', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    loop: true,

    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },

    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }

  });

  var swiperevent = new Swiper(".swiper-thumbs", {
    slidesPerView: 4,
    watchSlidesProgress: false,
    allowTouchMove: false,
  });

  var images = new Swiper(".swiper-images-window", {
    spaceBetween: 10,
    loop: true,
    allowTouchMove: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiperevent,
    },
  });

  $(".thumb-button").click(function() {
    $("#modal-swiper").addClass("show");
    $(".modal-backdrop.fade").addClass("show");
  });

  $(".btn-close").click(function() {
    $("#modal-swiper").removeClass("show");
    $(".modal-backdrop").removeClass("show");
  });

  $(".modal-backdrop").click(function() {
    $("#modal-swiper").removeClass("show");
    $(".modal-backdrop").removeClass("show");
  });



  if (window.location.pathname == '/') {
    $(".navbar").addClass('nav-block');
    $(".navbar").removeClass('navbar-color');
  };


  if (window.location.pathname == '/') {
    window.addEventListener("scroll", checkScroll);
    document.addEventListener("DOMContentLoadet", checkScroll);
    function checkScroll() {
      let scrollPos = window.scrollY;
      if (scrollPos <= 0) {
        $(".nav-block").removeClass('navbar-color');
      } else {
        $(".nav-block").addClass('navbar-color');
      }
    }
  }

  //////////////////////
  $('.validate_order_artist').keyup(function () {
    styleOrderArtistSubmitbutton(isOrderArtistValid());
  });


//////////////////////
  $('.validateContactform').keyup(function () {
    styleContactSubmitbutton(isContactValid());
  });


//////////////////////
  $('.show_more_events').on('click',function(event){
    event.preventDefault();

    let page = parseInt($('#page').val()) + 1
    let location_id = $('#realy_location_id').val();

    $.ajax({
      url: '/dashboard/show_more',
      method: "GET",
      dataType: 'script',
      data: {location_id: location_id, page: page}
    })
  });

   //////////////////////
   $('input#searching.form-control').on('input',function(){
    let search_params = $('input#searching').val()

    $.ajax({
      url: '/main_searches/index',
      method: "GET",
      dataType: 'script',
      data: {search: search_params}
    })

  });

  //////////////////////
  $('input#admin_event_searching.form-control').on('input',function(){
    let search_params = $('input#admin_event_searching').val()

    $.ajax({
      url: '/admin/adphotos/filtered_events',
      method: "GET",
      dataType: 'script',
      data: {search: search_params}
    })

  });


});


function styleOrderArtistSubmitbutton(isValidated) {
  let submit_button = document.getElementById("btn_contact_order_artist");

  if (isValidated) {
    submit_button.disabled = false
    submit_button.style.backgroundColor = "#1350c1", submit_button.style.color = "#e9eeff";
  } else {
    submit_button.style.backgroundColor = "#dbe2fb";
    submit_button.disabled = true
  }
};


function isOrderArtistValid() {
  if (!$('#contact_name').val()) { return }
  if (!$('#contact_date').val()) { return }
  if (!$('#contact_artist').val()) { return }
  if (!$('#contact_zahid').val()) { return }
  if (!$('#contact_budget').val()) { return }
  if (!$('#contact_phone').val()) { return }

  if (($('#contact_phone').val()).length != 10) { return }

  return true;
}

$(document).on("input", "input#contact_phone.form-control.validate_order_artist.number_phone", function(){
  if (($('#contact_phone').val()).length == 10 || ($('#contact_phone').val()).length == 0) {
    document.getElementById("number_phone_warning_for_order").style.display = "none";
  } else {
    document.getElementById("number_phone_warning_for_order").style.display = "block";
  }
});




function styleContactSubmitbutton(isValidated) {
  let submit_button = document.getElementById("btn-contact");

  if (isValidated) {
    submit_button.disabled = false
    submit_button.style.backgroundColor = "#1350c1", submit_button.style.color = "#e9eeff";
  } else {
    submit_button.style.backgroundColor = "#dbe2fb";
    submit_button.disabled = true
  }
};


function isContactValid() {
  if (!$('#contact_name').val()) { return }
  if (!$('#contact_message').val()) { return }
  if (!$('#contact_phone').val()) { return }
  if (($('#contact_phone').val()).length != 10) { return }

  return true;
}

$(document).on("input", "input#contact_phone.form-control.validateContactform.number_phone", function(){
  if (($('#contact_phone').val()).length == 10 || ($('#contact_phone').val()).length == 0) {
    document.getElementById("number_phone_warning").style.display = "none";
  } else {
    document.getElementById("number_phone_warning").style.display = "block";
  }
});

// when you first time click on location search FileSystemDirectoryReader
$(document).on("click", "input#location_id.form-control.search-form__field", function(){
  let value = $(this).val()
  let page = $('#page').val()

  $.ajax({
    url: '/dashboard/search_by_location',
    method: "GET",
    dataType: 'script',
    data: {location_value: value, delete_page: page }
  })
});

$(document).on("input", "input#location_id.form-control.search-form__field", function(){
  let value = $(this).val()
  let page = $('#page').val()

  $.ajax({
    url: '/dashboard/search_by_location',
    method: "GET",
    dataType: 'script',
    data: {location_value: value, delete_page: page }
  })
});


$(document).on("change", "#location_id.admin_locations_status", function(){
  let selected_location = $(this).val().toLowerCase();
  let url = $(this).attr("data-controller");

  $.ajax({
    url: url,
    method: "GET",
    dataType: 'script',
    data: {location_id: selected_location }
  })
});


$(document).on("click", "button#button-reset-location", function(){
  $("input#location_id").val("")
  $('#realy_location_id').val("");

  let page = $('#page').val()

  $.ajax({
    url: '/',
    method: "GET",
    dataType: 'script',
    data: { delete_page: page }
  })
});

// Search

